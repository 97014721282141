import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "46",
  height: "38",
  viewBox: "0 0 46 38",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#212529",
      d: "M12.9924 28.0229H0.5V37.9046H12.9924V28.0229Z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#212529",
      d: "M29.3779 14.4886H16.8855V37.9046H29.3779V14.4886Z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#212529",
      d: "M45.5 14.4886H33.0076V37.9046H45.5V14.4886Z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#FF7602",
      d: "M45.5 0.0954132H33.0076V9.9771H45.5V0.0954132Z"
    }, null, -1)
  ])))
}
export default { render: render }